import { Swiper } from 'swiper';
import { Navigation } from 'swiper/modules';
import 'swiper/scss';
import 'swiper/scss/navigation';

const sliderIndex = new Swiper('.slider-solutions', {
    modules: [Navigation],
    slidesPerView: 'auto',
    slidesOffsetAfter: 20,
    spaceBetween: 20,
    slidesPerGroupAuto: true,
    navigation: {
        nextEl: '.button-next',
        prevEl: '.button-prev',
    },
});

const sliderContent = new Swiper('.slider-article', {
    modules: [Navigation],
    loop: true,
    slidesPerView: 1,
    autoHeight: true,
    navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
    },
});

window.addEventListener('DOMContentLoaded', () => {
    if (document.querySelector('div.slider-solution')) {
        sliderIndex.enable();
    }
    if (document.querySelector('article.content-detail')) {
        sliderContent.enable();
    }
});
