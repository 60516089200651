import { gql } from '@apollo/client';

export const EXPERT_ADVICE = gql`
    mutation smartlightExpertAdvice($email: String!, $name: String!, $question: String!, $company: SmartlightCompanyEnum!) {
        smartlightExpertAdviceCreate(input: {email: $email, name: $name, question: $question, company: $company}) {
            expertAdvice {
                id
                created
                email
                name
                question
            }
            errors {
                code
                field
                message
            }
        }
    }
`;
