import $ from 'jquery';
import {
    SmartlightCompanyEnum,
    SmartlightExpertAdviceMutation,
    SmartlightExpertAdviceMutationVariables,
    apolloClient
} from '../../graphql';
import { EXPERT_ADVICE } from './mutations';

interface SmartlightExpertAdviceFormElements extends HTMLFormControlsCollection {
    name: HTMLInputElement;
    email: HTMLInputElement;
    company: HTMLSelectElement;
    question: HTMLInputElement;
}

interface SmartlightExpertAdviceForm extends HTMLFormElement {
  readonly elements: SmartlightExpertAdviceFormElements;
}

const resetCustomValidity = (ev: Event) => {
    const target = ev.target as HTMLInputElement;

    target.setCustomValidity('');
};

window.addEventListener('DOMContentLoaded', () => {
    const form = document.getElementById('expertAdviceForm') as SmartlightExpertAdviceForm | null;

    if (form) {
        form.elements.name.oninput = resetCustomValidity;
        form.elements.email.oninput = resetCustomValidity;
        form.elements.company.oninput = resetCustomValidity;
        form.elements.question.oninput = resetCustomValidity;

        form.onsubmit = async (ev: SubmitEvent) => {
            ev.preventDefault();
            const target = ev.currentTarget as SmartlightExpertAdviceForm;
            const fields = target.elements;

            await apolloClient.mutate<SmartlightExpertAdviceMutation, SmartlightExpertAdviceMutationVariables>({
                mutation: EXPERT_ADVICE,
                variables: {
                    email: fields.email.value,
                    name: fields.name.value,
                    company: SmartlightCompanyEnum[fields.company.value],
                    question: fields.question.value,
                },
                update: (_, { data }) => {
                    if (data?.smartlightExpertAdviceCreate?.expertAdvice?.id) {
                        form.reset();

                        $('#expertAdviceFormReveal').foundation('open');

                    } else if (data?.smartlightExpertAdviceCreate?.errors) {
                        data.smartlightExpertAdviceCreate.errors.map((error) => {
                            if (error.field === 'email') {
                                form.email.setCustomValidity(error.message ?? 'Ошибка');
                                form.email.reportValidity();
                            }
                            if (error.field === 'name') {
                                form.fio.setCustomValidity(error.message ?? 'Ошибка');
                                form.fio.reportValidity();
                            }
                            if (error.field === 'company') {
                                form.company.setCustomValidity(error.message ?? 'Ошибка');
                                form.company.reportValidity();
                            }
                            if (error.field === 'question') {
                                form.question.setCustomValidity(error.message ?? 'Ошибка');
                                form.question.reportValidity();
                            }
                        });
                    }
                }
            });
        };
    }
});
