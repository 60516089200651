import $ from 'jquery';

window.addEventListener('DOMContentLoaded', () => {
    $('.entry-hover').on('hover', () => {
        $('.entry.wrap-fill').toggleClass('wrap-fill-hover');
        $('.entry.wrap-border').toggleClass('wrap-border-hover');
        $('.entry.line').toggleClass('line-hover');
    });

    $('.inserts-hover').on('hover', () => {
        $('.inserts.wrap-fill').toggleClass('wrap-fill-hover');
        $('.inserts.wrap-border').toggleClass('wrap-border-hover');
        $('.inserts.line').toggleClass('line-hover');
    });

    $('.facadepanels-hover').on('hover', () => {
        $('.facadepanels.wrap-fill').toggleClass('wrap-fill-hover');
        $('.facadepanels.wrap-border').toggleClass('wrap-border-hover');
        $('.facadepanels.line').toggleClass('line-hover');
    });

    $('.roof-hover').on('hover', () => {
        $('.roof.wrap-fill').toggleClass('wrap-fill-hover');
        $('.roof.wrap-border').toggleClass('wrap-border-hover');
        $('.roof.line').toggleClass('line-hover');
    });
});
