import $ from 'jquery';

window.addEventListener('DOMContentLoaded', () => {
    $('.livingroom-hover').on('hover', () => {
        $('.livingroom.wrap-fill').toggleClass('wrap-fill-hover');
        $('.livingroom.wrap-border').toggleClass('wrap-border-hover');
        $('.livingroom.line').toggleClass('line-hover');
    });

    $('.laundryroom-hover').on('hover', () => {
        $('.laundryroom.wrap-fill').toggleClass('wrap-fill-hover');
        $('.laundryroom.wrap-border').toggleClass('wrap-border-hover');
        $('.laundryroom.line').toggleClass('line-hover');
    });

    $('.bathroom-hover').on('hover', () => {
        $('.bathroom.wrap-fill').toggleClass('wrap-fill-hover');
        $('.bathroom.wrap-border').toggleClass('wrap-border-hover');
        $('.bathroom.line').toggleClass('line-hover');
    });

    $('.sauna-hover').on('hover', () => {
        $('.sauna.wrap-fill').toggleClass('wrap-fill-hover');
        $('.sauna.wrap-border').toggleClass('wrap-border-hover');
        $('.sauna.line').toggleClass('line-hover');
    });

    $('.bedroom-hover').on('hover', () => {
        $('.bedroom.wrap-fill').toggleClass('wrap-fill-hover');
        $('.bedroom.wrap-border').toggleClass('wrap-border-hover');
        $('.bedroom.line').toggleClass('line-hover');
    });

    $('.garage-hover').on('hover', () => {
        $('.garage.wrap-fill').toggleClass('wrap-fill-hover');
        $('.garage.wrap-border').toggleClass('wrap-border-hover');
        $('.garage.line').toggleClass('line-hover');
    });

    $('.kitchen-hover').on('hover', () => {
        $('.kitchen.wrap-fill').toggleClass('wrap-fill-hover');
        $('.kitchen.wrap-border').toggleClass('wrap-border-hover');
        $('.kitchen.line').toggleClass('line-hover');
    });

    $('.hall-hover').on('hover', () => {
        $('.hall.wrap-fill').toggleClass('wrap-fill-hover');
        $('.hall.wrap-border').toggleClass('wrap-border-hover');
        $('.hall.line').toggleClass('line-hover');
    });

    $('.vestibule-hover').on('hover', () => {
        $('.vestibule.wrap-fill').toggleClass('wrap-fill-hover');
        $('.vestibule.wrap-border').toggleClass('wrap-border-hover');
        $('.vestibule.line').toggleClass('line-hover');
    });
});
