import $ from 'jquery';

window.addEventListener('DOMContentLoaded', () => {
    $('.anchor-zone').on('click', function() {
        const a: any = $(this).attr('xlink:href'),
            winHeight: any = $(window).height(),
            posBlock: any = $(a).offset();
        $('body,html').animate({
            scrollTop: (posBlock.top - winHeight / 3),
        }, 1500);
    });

    $('a[href^="#map"]').on('click', function() {
        const a: any = $(this).attr('href'),
            posBlock: any = $(a).offset();
        $('body,html').animate({
            scrollTop: posBlock.top,
        }, 1500);
    });
});
